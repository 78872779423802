<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4 noprint">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link>
                </p>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ name }}</h1>
                <p class="text-caption text-center">Account overview</p>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center" v-if="account">{{ account.name }}</h1>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10 mt-8" v-if="unauthorizedError">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-alert type="warning">
                        <span class="text-larger-1">Account not found or access denied.</span><br/>
                        <!-- NOTE: if user is not signed in they don't even see this, they see the sign in dialogue; so this link is specifically for them to sign in as someone *else* in case they followed a bookmark or link to an account that isn't associated to their current profile -->
                        <span class="text-smaller-1"><a href="#" class="text-alert" @click="navigateToSignIn">Sign in as a different user</a></span>
                    </v-alert>
                </v-col>
            </v-row>

            <v-row justify="center" class="py-5 px-10 mt-8" v-if="account">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-alert type="warning" v-if="isAccountBillingSummaryReady && (!accountBillingSummary || accountBillingSummary.error)">
                        Billing data not available at this time.
                        <a href="#" class="text-alert" @click="navigateToBilling">Manage billing</a>
                    </v-alert>
                    <v-card>
                        <v-app-bar :color="primaryColor" dark flat dense>
                            <v-app-bar-title>Account overview</v-app-bar-title>
                            <v-spacer></v-spacer>
                            <v-menu offset-y left open-on-click open-on-hover close-delay="100">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on">
                                        <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                                    </v-btn>
                                </template>
                                <v-list class="ma-0 pa-0">
                                    <v-list-item-group>
                                    <v-list-item :to="{ name: 'account-settings', params: { accountId: this.$route.params.accountId } }">
                                        <v-list-item-icon>
                                            <font-awesome-icon :icon="['fas', 'cog']" :color="primaryColor" fixed-width/>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Settings</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-menu>
                        </v-app-bar>
                        <v-card-text>
                            <template v-if="isDnsHostingEnabled">
                            <p class="text-overline mb-0 mt-0">Domains</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-search-domain', params: { accountId: this.$route.params.accountId } }">See all domains</router-link>
                                <span v-if="accountDataSummary" class="ml-2">({{ accountDataSummary.domain_count }})</span>
                            </p>
                            <p class="mb-0 pb-0" v-if="accountDataSummary && accountDataSummary.domain_verification_count">
                                Pending verifications<span v-if="accountDataSummary" class="ml-2">({{ accountDataSummary.domain_verification_count}})</span>
                            </p>
                            </template>
                            <template v-if="!isDnsHostingEnabled">
                                <p class="text-overline mb-0 mt-0">Domains</p>
                                <!-- TODO: add a notice and button to subscribe to a plan!! -->
                                <p>You don't have a DNS hosting plan.</p>
                                <p><a href="#" @click="subscribeToDnsHosting"><strong>Get DNS hosting</strong></a></p>
                                <!-- <v-btn @click="subscribeToDnsHosting" :color="primaryColor" style="color: white;">Get DNS hosting</v-btn> -->
                            </template>
                            <!-- <p class="mb-0 pb-0" v-if="accountDataSummary">
                                DNS records<span v-if="accountDataSummary" class="ml-2">({{ accountDataSummary.dns_record_count}})</span>
                            </p> -->

                            <template v-if="isDynamicDnsHostingEnabled">
                            <p class="text-overline mb-0 mt-8">Dynamic DNS</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-search-dynamic-dns-record', params: { accountId: this.$route.params.accountId } }">See all Dynamic DNS records</router-link>
                                <span v-if="accountDataSummary" class="ml-2">({{ accountDataSummary.dynamic_dns_count }})</span>
                            </p>
                            </template>
                            <template v-if="!isDynamicDnsHostingEnabled">
                                <!-- TODO: add a notice and button to subscribe to a plan!! -->
                            </template>

                            <!-- TODO: restore the authorized users link after we implement all that functionality; for now it's disabled because it's confusing for people to click onthis link and not be able to do antyhing on the users page -->
                            <!-- <p class="text-overline mb-0 mt-8">Authorized Users</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-search-user', params: { accountId: this.$route.params.accountId } }">See all authorized users</router-link>
                                <span v-if="accountDataSummary" class="ml-2">({{ accountDataSummary.user_count }})</span>
                            </p> -->
                            <template v-if="accountDataSummary && (accountDataSummary.domain_dispute_claimant_count > 0 || accountDataSummary.domain_dispute_defendant_count > 0)">
                            <p class="text-overline mb-0 mt-8">Disputes</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-search-domain-dispute', params: { accountId: this.$route.params.accountId }, query: { role: 'claimant' } }">See all disputes for a domain name</router-link>
                                <span v-if="accountDataSummary" class="ml-2">({{ accountDataSummary.domain_dispute_claimant_count }})</span>
                            </p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-search-domain-dispute', params: { accountId: this.$route.params.accountId }, query: { role: 'defendant' } }">See all disputes against you</router-link>
                                <span v-if="accountDataSummary" class="ml-2">({{ accountDataSummary.domain_dispute_defendant_count }})</span>
                            </p>
                            </template>

                            <p class="text-overline mb-0 mt-8">Tools</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-view-nameservers', params: { accountId: this.$route.params.accountId } }">Check nameservers for a domain</router-link>
                            </p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-view-whois', params: { accountId: this.$route.params.accountId } }">Check whois for a domain</router-link>
                            </p>
                            <!-- <AccountViewList :list="list"/> -->
                            <!-- <RealmList @selected="onSelectRealm" create class="mt-12"/> -->

                            <template v-if="isAccountBillingSummaryReady && accountBillingSummary && !accountBillingSummary.error">
                            <p class="text-overline mb-0 mt-8">Billing</p>
                            <p class="mb-0 pb-0" v-if="isDnsHostingEnabled">
                                Active subscription: {{ dnsHostingTypeDisplayName(accountBillingSummary.dns_hosting_type) }}
                            </p>
                            <p class="mb-0 pb-0">
                                Account status: {{ accountBillingSummary.is_open ? 'open' : 'closed' }}
                            </p>
                            <p class="mb-0 pb-0">
                                Balance: ${{ accountBillingSummary.balance_amount }}
                                Credit: ${{ accountBillingSummary.credit_amount }}
                            </p>
                            <p class="mb-0 pb-0">
                                <a href="#" @click="navigateToBilling">
                                {{ accountBillingSummary.is_payment_due ? 'Payment is due' : 'No payment is due' }}
                                {{ accountBillingSummary.is_payment_late ? '(late)' : '' }}
                                </a>
                            </p>
                            </template>

                            <!-- TODO: if there's a feature to designate a person to take over account if account owner dies or is incapacitated, that setting should be here and we should show who it is and have a button to change it (or it should be in unicorn springs) -->
                            <!-- TODO: if there's a feature to transfer the account to someone else, that button should be here  (or maybe in unicorn springs)-->

                            <!-- <p class="text-overline mb-0 mt-8">Other</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'something-useful-tbd', params: { accountId: this.$route.params.accountId } }">How to do something useful TBD</router-link>
                            </p> -->

                            <!-- <template v-if="isPermitServiceAdmin">
                            <p class="text-overline mb-0 mt-10">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="purple--text"/></p>
                            <p class="mb-0 pb-0">
                            </p>
                            </template> -->

                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>
a.text-alert, a.text-alert:visited {
    color: #ffffff;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';
import { SSO_TAG_UNICORNSPRINGS_PORTAL } from '@/sdk/constants';
// import UserList from '@/components/account-dashboard/UserList.vue';

export default {
    components: {
        // UserList,
    },
    data: () => ({
        accountDataSummary: null,
        accountBillingSummary: null,
        accountDisputeSummary: null,
        error: null,
        isAccountBillingSummaryReady: false, // prevents flicker on related UI items
        unauthorizedError: false,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
            account: (state) => state.account,
            focus: (state) => state.focus,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
        }),
        name() {
            return this.account?.name ?? 'Unknown';
        },
        // isPermitServiceAdmin() {
        //     return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        // },
        isDnsHostingEnabled() {
            return ['essential', 'business', 'enterprise'].includes(this.accountBillingSummary?.dns_hosting_type);
        },
        isDynamicDnsHostingEnabled() {
            return false;
        },
    },
    watch: {
        focus() {
            // reload data when user returns to this tab
            this.loadAccountData();
        },
    },
    methods: {
        // TODO: get the display name from unicorn springs service name; right now we're not saving that into the account status when we do the billing check in account api
        dnsHostingTypeDisplayName(ref) {
            let displayName = null;
            switch (ref) {
            case 'essential':
                displayName = 'LibertyDNS Essential';
                break;
            case 'professional':
                displayName = 'LibertyDNS Professional';
                break;
            case 'business':
                displayName = 'LibertyDNS Business';
                break;
            case 'enterprise':
                displayName = 'LibertyDNS Enterprise';
                break;
            default:
                displayName = 'LibertyDNS';
                break;
            }
            return displayName;
        },
        async loadAccountData() {
            const isAuthorized = await this.checkAccount();
            if (isAuthorized) {
                this.loadAccountBillingSummary();
                this.loadAccountDataSummary();
                this.loadAccountDisputeSummary();
            }
        },
        /**
         * The account data summary, billing summary, etc. call APIs that check if the user
         * has admin access to the specified account. However, if the user arrived here after
         * creating a new account, there may be a brief window of time when it appears the
         * user isn't authorized yet, so we check with the server to avoid the unauthorized
         * error. If the server still doesn't see the access then the next attempt to load
         * account data will also fail and then we'll show the error message.
         */
        async checkAccount() {
            try {
                this.$store.commit('loading', { checkAccount: true });
                const result = await this.$client.user(this.session.userId).self.check({ item: 'account', account_id: this.$route.params.accountId });
                console.log(`AccountDashboard.vue: checkAccount result ${JSON.stringify(result)}`);
                return true;
            } catch (err) {
                console.error('checkAccount failed', err);
                if (err.response.status === 403) {
                    // this.$bus.$emit('snackbar', { type: 'error', headline: 'Account not found or access denied' });
                    this.unauthorizedError = true;
                }
                return false;
            } finally {
                this.$store.commit('loading', { checkAccount: false });
            }
        },
        async loadAccountDataSummary() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccountDataSummary: true });
                const response = await this.$client.account(this.$route.params.accountId).self.check({ item: 'data' });
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.accountDataSummary = response; // {"domain_count":2,"domain_verification_count":0,"dynamic_dns_count":0,"user_count":1,"dns_record_count":4}
                } else {
                    this.accountDataSummary = null;
                }
            } catch (err) {
                console.error('loadAccountDataSummary: failed to load account report', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccountDataSummary: false });
            }
        },
        async loadAccountBillingSummary() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccountBillingSummary: true });
                const response = await this.$client.account(this.$route.params.accountId).self.check({ item: 'billing' });
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.accountBillingSummary = response; // {"is_open":true,"is_payment_due":true,"is_payment_late":false,"balance_amount":"100.00","credit_amount":"20.00","status_on":1234567890}
                } else {
                    this.accountBillingSummary = null;
                }
            } catch (err) {
                console.error('loadAccountBillingSummary: failed to load account report', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccountBillingSummary: false });
                this.isAccountBillingSummaryReady = true;
            }
        },
        async loadAccountDisputeSummary() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccountDisputeSummary: true });
                const response = await this.$client.account(this.$route.params.accountId).self.check({ item: 'dispute' });
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.accountDisputeSummary = response; // {"domain_dispute_defendant":0,"domain_dispute_claimant":0}
                } else {
                    this.accountDisputeSummary = null;
                }
            } catch (err) {
                console.error('loadAccountDisputeSummary: failed to load account report', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccountDisputeSummary: false });
            }
        },
        async navigateToBilling() {
            try {
                this.error = false;
                this.$store.commit('loading', { navigateToBilling: true });
                // const response = await this.$client.account(this.$route.params.accountId).unicornsprings.connect({ intent: 'billing' });
                const response = await this.$client.main().authn.ssoRedirect({ to_tag: SSO_TAG_UNICORNSPRINGS_PORTAL, account_id: this.$route.params.accountId });
                // console.log(`response ${JSON.stringify(response)}`); // {"redirect":"https://...","redirect_max_seconds":300}
                if (response?.redirect) {
                    window.location.href = response.redirect;
                }
            } catch (err) {
                console.error('navigateToBilling: failed to get link to Unicorn Springs', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to connect with Unicorn Springs' });
            } finally {
                this.$store.commit('loading', { navigateToBilling: false });
            }
        },
        async subscribeToDnsHosting() {
            try {
                this.error = false;
                this.$store.commit('loading', { subscribeToDnsHosting: true });
                const response = await this.$client.account(this.$route.params.accountId).unicornsprings.connect({ intent: 'cart', product_ref: 'libertydns-essential' }); // TODO: replace product_ref 'essential' with a catalog tag like 'dnshosting' that will bring up all three plans 'essential', 'business', and 'enterprise' (or whatever is published)
                // console.log(`response ${JSON.stringify(response)}`); // {"redirect":"https://...","redirect_max_seconds":300}
                if (response?.redirect) {
                    window.location.href = response.redirect;
                }
            } catch (err) {
                console.error('subscribeToDnsHosting: failed to get link to Unicorn Springs', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to connect with Unicorn Springs' });
            } finally {
                this.$store.commit('loading', { subscribeToDnsHosting: false });
            }
        },
        async navigateToSignIn() {
            try {
                this.$store.commit('loading', { navigateToSignIn: true });
                this.$router.push({ name: 'login', query: { intent: 'switch-user' } }); // TODO: parameter to redirect user back to this page after sign in
            } catch (err) {
                console.error('navigateToSignIn: failed to sign out', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to sign in', message: 'Please reload the page and try again' });
            } finally {
                this.$store.commit('loading', { navigateToSignIn: false });
            }
        },
    },
    mounted() {
        this.loadAccountData();
    },
};
</script>
